import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { modalActions } from '../components/Modals/state'
import { Header, Container, Grid, Segment } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'
import MapContact from '../components/Map/MapContact'

import { getFluidImage, getRawImage } from '../utils/images'
import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'
import HubspotForm from 'react-hubspot-form'

class Contact extends Component {
  render() {
    const { t, pageContext } = this.props
    const cloudinary = 'v1667988912/wordpress/Header_Kontakt_x2wxg8'
    const transformation = ''

    return (
      <>
        <Helmet>
          <title>{t('contact.header.title') + t('htmlTitleSuffix')}</title>

          <meta name='description' content={t('contact.header.subtitle')} />
          <meta name='image' content={getRawImage({ cloudinary })} />

          <meta property='og:title' content={t('contact.header.title')} />
          <meta
            property='og:description'
            content={t('contact.header.subtitle')}
          />
          <meta property='og:image' content={getRawImage({ cloudinary })} />

          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:creator' content='Tischler Reisen' />
          <meta name='twitter:title' content={t('contact.header.title')} />
          <meta
            name='twitter:description'
            content={t('contact.header.subtitle')}
          />
          <meta name='twitter:image' content={getRawImage({ cloudinary })} />
        </Helmet>

        <Layout pageContext={pageContext}>
          <Img
            loading='lazy'
            fadeIn={false}
            fluid={getFluidImage({ cloudinary, transformation, ar: 2.5 })}
            alt={t('contact.image.alt')}
          />

          <PageHeader title={t('contact.pageTitle')} />

          <Segment vertical padded='very' basic style={{ width: '100%' }}>
            <Container>
              <Grid style={{ maxWidth: '650px', margin: 'auto' }}>
                <Grid.Row computer={16} tablet={16} mobile={16}>
                  <Segment vertical basic>
                    <Header as='h2' color='red'>
                      {t('contact.openningHours.title')}
                    </Header>
                    <p>{t('contact.openningHours.text')}</p>
                    <p>{t('contact.openningHours.text2')}</p>
                    <p>{t('contact.openningHours.text3')}</p>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment vertical basic>
                    <Header as='h2' color='red'>
                      {t('contact.address.title')}
                    </Header>
                    {t('contact.address.address')
                      .split('/n')
                      .map((line, key) => (
                        <div key={key}>{line}</div>
                      ))}
                    <br />
                    {t('contact.address.phone1Label')}{' '}
                    <a href={`tel:${t('contact.address.phone1')}`}>
                      {t('contact.address.phone1')}
                    </a>{' '}
                    <br />
                    {t('contact.address.email')}:{' '}
                    <a href={'mailto: ' + t('contact.address.emailLink')}>
                      {t('contact.address.emailLabel')}
                    </a>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment
                    vertical
                    basic
                    style={{ width: '100%' }}
                    id='hs-form'
                  >
                    <Header as='h2' color='red'>
                      {t('contact.contactUs')}
                    </Header>
                    <HubspotForm
                      portalId='20039986'
                      formId='368e2f32-d100-4aa3-8a14-24d5d81bc44d'
                      onSubmit={() => console.log('Submit!')}
                      onReady={form => console.log('Form ready!')}
                      loading={<div>Loading...</div>}
                    />
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment vertical basic style={{ width: '100%' }}>
                    <Header as='h2' color='red'>
                      {t('contact.location')}
                    </Header>
                    <MapContact mapMarkers={pageContext.mapMarkers} />
                  </Segment>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Layout>
      </>
    )
  }
}
/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(connect(null, mapDispatchToProps)(Contact))
